<template>
    <div class="flex flex-row items-center">
        <ProfileImage
            :email="profile.email"
            :alt="`Picture of ${firstName} ${lastName}`"
            :initials="`${firstName.charAt(0)}${lastName.charAt(0)}`"
            :dimensions="dimensions"
            :class="{
                'h-12 w-12': size === 'normal',
                'h-10 w-10': size === 'small',
            }"
        />
        <span
            v-if="!compact"
            class="ml-3"
            :class="{
                'text-base': size === 'normal',
                'text-sm': size === 'small',
                'text-gray-700': theme === 'dark',
                'text-white': theme === 'light',
            }">{{ fullName }}</span>
    </div>
</template>

<script>
export default {
    name: 'ProfileId',

    props: {
        profile: {
            type: Object,
            required: true,
        },

        theme: {
            type: String,
            default: 'dark',
        },

        size: {
            type: String,
            default: 'normal',
        },

        compact: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        firstName() {
            const { profile } = this;
            if (profile.name) return profile.name.split(' ')[0];
            if (profile.firstName) return profile.firstName;
            return '';
        },

        lastName() {
            const { profile } = this;
            if (profile.name) return profile.name.split(' ')[1];
            if (profile.lastName) return profile.lastName;
            return '';
        },

        fullName() {
            return `${this.firstName} ${this.lastName}`;
        },

        dimensions() {
            return this.size === 'small' ? 100 : 200;
        },
    },

    components: {
        ProfileImage: () => import('@/components/ui/ProfileImage.vue'),
    },
};
</script>
